var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('vx-card',{staticClass:"mb-base"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit(validate)}}},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ชื่อโซน")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ชื่อโซน (TH)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full required",attrs:{"name":"title","label":"TH"},model:{value:(_vm.formData.title.th.value),callback:function ($$v) {_vm.$set(_vm.formData.title.th, "value", $$v)},expression:"formData.title.th.value"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('vs-input',{staticClass:"w-full mt-4",attrs:{"name":"title-en","label":"EN"},model:{value:(_vm.formData.title.en.value),callback:function ($$v) {_vm.$set(_vm.formData.title.en, "value", $$v)},expression:"formData.title.en.value"}}),_c('vs-input',{staticClass:"w-full mt-4",attrs:{"name":"title-cn","label":"CN"},model:{value:(_vm.formData.title.cn.value),callback:function ($$v) {_vm.$set(_vm.formData.title.cn, "value", $$v)},expression:"formData.title.cn.value"}})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("ระบุ URL พิเศษ (Slug)")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("URL พิเศษนี้ใช้รวมกันทั้ง 3 ภาษา")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Slug","rules":"required|special_charecter"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"title"},on:{"focus":function () { return _vm.isSlugExist = false; }},model:{value:(_vm.formData.slug),callback:function ($$v) {_vm.$set(_vm.formData, "slug", $$v)},expression:"formData.slug"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('br',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSlugExist && errors.length > 0),expression:"isSlugExist && errors.length > 0"}]}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSlugExist),expression:"isSlugExist"}],staticClass:"text-danger text-sm"},[_vm._v("slug นี้มีในระบบแล้ว กรุณากรอกข้อมูลใหม่")])]}}],null,true)}),_c('span',{staticClass:"text-gray flex flex-col"},[_vm._v("ตัวอย่าง slug: chiangmai, rangsit-lamlukka")])],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("จังหวัด")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"จังหวัด","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-col w-5/12"},[_c('vs-select',{staticClass:"selectExample",attrs:{"name":"province","icon":"arrow_downward","autocomplete":""},model:{value:(_vm.formData.provinceId),callback:function ($$v) {_vm.$set(_vm.formData, "provinceId", $$v)},expression:"formData.provinceId"}},_vm._l((_vm.provinces.data),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.title}})}),1),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("คุณสมบัติข้อมูล")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"คุณสมบัติข้อมูล","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ul',[_c('li',[_c('vs-radio',{attrs:{"vs-name":"คุณสมบัติข้อมูล","vs-value":"website"},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}},[_vm._v("ใช้บนเว็บไซต์\n                  ")])],1),_c('li',[_c('vs-radio',{attrs:{"vs-name":"คุณสมบัติข้อมูล","vs-value":"flm"},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}},[_vm._v("สำหรับ FLM")])],1)]),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1)],1)]),_c('vx-card',[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดโดย")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formData.updated_by))])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดวันที่")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.formData.updated_at)))])])],1),_c('FormLayout',[_c('FormLabel'),_c('FormContent',[_c('vs-button',{staticClass:"mr-2",on:{"click":function($event){return _vm.submit(validate)}}},[_vm._v("Submit")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }