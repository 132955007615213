<template>
  <div>
    <ValidationObserver v-slot="{ errors, validate }">
      <vx-card class="mb-base">
        <form @submit.prevent="submit(validate)">
          <FormLayout>
            <FormLabel>
              <span>ชื่อโซน</span>
              <span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="ชื่อโซน (TH)"
                rules="required"
              >
                <vs-input
                  v-model="formData.title.th.value"
                  class="w-full required"
                  name="title"
                  label="TH"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
              <vs-input
                v-model="formData.title.en.value"
                class="w-full mt-4"
                name="title-en"
                label="EN"
              />
              <vs-input
                v-model="formData.title.cn.value"
                class="w-full mt-4"
                name="title-cn"
                label="CN"
              />
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span class="required">ระบุ URL พิเศษ (Slug)</span> <span class="text-danger"> *</span><br>
              <span class="text-warning text-sm">URL พิเศษนี้ใช้รวมกันทั้ง 3 ภาษา</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Slug" 
                rules="required|special_charecter">
                <vs-input
                  v-model="formData.slug"
                  class="w-full"
                  name="title"
                  @focus="() => isSlugExist = false"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
                <br v-show="isSlugExist && errors.length > 0" />
                <span 
                  v-show="isSlugExist" 
                  class="text-danger text-sm"
                >slug นี้มีในระบบแล้ว กรุณากรอกข้อมูลใหม่</span
                >
              </ValidationProvider>
              <span class="text-gray flex flex-col">ตัวอย่าง slug: chiangmai, rangsit-lamlukka</span>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>จังหวัด</span>
              <span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="จังหวัด"
                rules="required"
              >
                <div class="vx-col w-5/12">
                  <vs-select
                    v-model="formData.provinceId"
                    name="province"
                    class="selectExample"
                    icon="arrow_downward"
                    autocomplete
                  >
                    <vs-select-item
                      v-for="(item, index) in provinces.data"
                      :value="item.id"
                      :text="item.title"
                      :key="index"
                    />
                  </vs-select>
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">{{
                      errors[0]
                    }}</span>
                </div>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>คุณสมบัติข้อมูล</span>
              <span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="คุณสมบัติข้อมูล"
                rules="required"
              >
                <ul>
                  <li>
                    <vs-radio
                      v-model="formData.type"
                      vs-name="คุณสมบัติข้อมูล"
                      vs-value="website"
                    >ใช้บนเว็บไซต์
                    </vs-radio>
                  </li>
                  <li>
                    <vs-radio
                      v-model="formData.type"
                      vs-name="คุณสมบัติข้อมูล"
                      vs-value="flm"
                    >สำหรับ FLM</vs-radio
                    >
                  </li>
                </ul>
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
        </form>
      </vx-card>
      <vx-card>
        <FormLayout>
          <FormLabel>
            <span>แก้ไขล่าสุดโดย</span>
          </FormLabel>
          <div class="flex items-center vx-col w-full md:w-6/12">
            <span class="mt-2"> {{formData.updated_by}}</span>
          </div>
        </FormLayout>
        <FormLayout>
          <FormLabel>
            <span>แก้ไขล่าสุดวันที่</span>
          </FormLabel>
          <div class="flex items-center vx-col w-full md:w-6/12">
            <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
          </div>
        </FormLayout>
        <FormLayout>
          <FormLabel />
          <FormContent>
            <vs-button 
              class="mr-2" 
              @click="submit(validate)">Submit</vs-button>
              <!-- <vs-button color="warning" type="border" @click="context.reset"
              >Reset</vs-button
            > -->
          </FormContent>
        </FormLayout>
      </vx-card>
    </ValidationObserver>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from '@vue/composition-api'
import get from 'lodash/get'
// import map from 'lodash/map'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import useDatepicker from '@/use/useDatepicker'
import useNotify from '@/use/useNotify'

const sortThaiDictionary = (list = []) => {
  const newList = [...list]
  newList.sort((a, b) => a.title.localeCompare(b.title, 'th'))
  return newList
}

export default {
  name: 'ZoneForm',
  setup(props, ctx) {
    const {
      root: { $vs, $store, $router },
    } = ctx
    const routePrefix = ref('zone')
    const isSlugExist = ref(false)
    const formData = reactive({
      id: null,
      slug: null,
      title: {
        th: {
          id: null,
          value: '',
        },
        en: {
          id: null,
          value: '',
        },
        cn: {
          id: null,
          value: '',
        },
      },
      provinceId: null,
      type: 'website',
      created_by: null,
      updated_by: null,
      updated_at: null,
    })
    const provinces = reactive({
      data: [],
    })

    const { success: notifySuccess, error: notifyError } = useNotify(ctx)

    onMounted(() => {
      $store.dispatch('province/fetch').then((items) => {
        provinces.data = sortThaiDictionary(items)
      })

      const id = get($router.currentRoute.params, 'id', null)
      if (id) {
        formData.id = id
        $store
          .dispatch('zone/fetchOne', id)
          .then((result) => {
            formData.provinceId = result.province_id
            formData.type = result.type
            formData.slug = result.slug
            formData.created_by = result.created_by
            formData.updated_by = result.updated_by
            formData.updated_at = result.updated_at

            const lang = ['th', 'en', 'cn']
            forEach(lang, (item) => {
              const data = find(result.zone_translates, ['lang', item])
              formData.title[item].value = data ? data.value : ''
              formData.title[item].id = data ? data.id : null
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })

    const submit = async (validate) => {
      if (formData.slug) {
        formData.slug =  formData.slug.replace(/\s+/g, '-')
        const isExist = await $store.dispatch('zone/checkSlugExist', formData)
        isSlugExist.value = isExist

        if (isExist) {
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0

          return notifyError({
            text: "มี Slug นี้ในระบบแล้ว"
          })
        }
      }

      validate().then((result) => {
        if (!result) {
          // scroll to top when not input slug
          if (!formData.slug) {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
            return notifyError({
              text: "กรุณาระบุ Slug"
            })
          }

          notifyError({
            text: 'กรุณาตรวจสอบข้อมูลที่กรอก',
          })
          return
        }

        $vs.loading()

        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let created_by = formData.created_by
        // add user created by
        if (!formData.created_by) {
          created_by = email
        }
        const updated_by = email

        $store
          .dispatch(
            `${routePrefix.value}/${formData.id === null ? 'addItem' : 'update'}`,
            {
              ...formData,
              created_by,
              updated_by
            },
          )
          .then(() => {
            $router.push({ name: 'property-zone' })
            $vs.loading.close()
            notifySuccess({
              text: `${isEmpty(formData.id) ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย`,
            })
          })
          .catch((error) => {
            $vs.loading.close()
            notifyError({ text: JSON.stringify(error) })
          })
      })
    }

    const formatDate = (date) => {
      moment.locale('th')
      return date ? moment(date).format('LLL') : ''
    }

    return {
      provinces,
      formData,
      routePrefix,
      submit,
      useDatepicker: useDatepicker(),
      formatDate,
      isSlugExist
    }
  },
}
</script>

